@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/HelveticaNeue.otf");
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url("./fonts/HelveticaNeueBold.otf");
}

@font-face {
  font-family: "HelveticaNeueMedium";
  src: url("./fonts/HelveticaNeueMedium.otf");
}

@font-face {
  font-family: "BenHand";
  src: url("./fonts/BenHand.otf");
}

body {
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
